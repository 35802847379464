@font-face {
	font-family: 'Actay Wide';
	src: url(../assets/fonts/ActayWide-Bold.otf);
	font-style: normal;
}

@font-face {
	font-family: 'Actay';
	src: url(../assets/fonts/Actay-Regular.otf);
	font-style: normal;
	font-weight: 400;
}
